<template>
  <div class="ym-page">
    <el-card class="card-box"
             v-for="(node, index) in nodeData"
             :key="index"
             shadow="nerver">
      <div slot="header">
        <el-row>
          <el-col :xl='12'
                  :lg='12'
                  :md='12'
                  :xs='12'>
            <div class='line-name'>
              <span>节点信息</span>
            </div>
          </el-col>
          <!-- <el-col style="text-align: right"
                  :xl='12'
                  :lg='12'
                  :md='12'
                  :xs='12'>
            <span class="head-title">频率</span>
            <el-select class="list-select"
                       @change="handleChange"
                       v-model="time"
                       size="mini"
                       placeholder="请选择">
              <el-option v-for="item in timeOpt"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-col> -->
        </el-row>
      </div>
      <el-row :gutter='20'>
        <el-col class="Bottom"
                :xl="10"
                :lg="12"
                :md="24"
                :xs="24">
          <el-card shadow="never"
                   class="left-box">
            <div class="line-name small-bottom">节点：{{node.Address}}</div>
            <div class="line-content small-bottom">
              <span class="line-left">节点类型：</span>
              <div class="line-right-2">{{ node.PeerType }}</div>
              <span class="line-left">待&ensp;处&ensp;理：</span>
              <div class="line-right-1">{{ node.Pending }}</div>
            </div>
            <div class="line-content small-bottom">
              <span class="line-left">分片名称：</span>
              <div class="line-right-2">{{ node.ShardName }}</div>
              <span class="line-left">已&ensp;处&ensp;理：</span>
              <div class="line-right-1">{{ node.Completed }}</div>
            </div>
            <div class="line-content small-bottom">
              <span class="line-left">网络端口：</span>
              <div class="line-right-2">{{ node.Port }}</div>
              <span class="line-left">版&ensp;本&ensp;号：</span>
              <div class="line-right-1">{{ node.Version }}</div>
            </div>
            <div class="line-content small-bottom">
              <span class="line-left">区块高度：</span>
              <div class="line-right-2">{{ node.Height }}</div>
              <span class="line-left">时&ensp;间&ensp;戳：</span>
              <div class="line-right-1">{{ node.Timestamp }}</div>
            </div>
            <div class="line-content small-bottom">
              <span class="line-left">哈&ensp;希&ensp;值：</span>
              <div class="line-right shenglue-2">{{ node.Hash }}</div>
              <i class="el-icon-document-copy"
                 style="margin-left:10px"
                 @click="copy($event, node.Hash)"></i>
            </div>
            <div class="line-content small-bottom">
              <span class="line-left">父&ensp;哈&ensp;希：</span>
              <div class="line-right shenglue-2">{{ node.ParentHash }}</div>
              <i class="el-icon-document-copy"
                 style="margin-left:10px"
                 @click="copy($event, node.ParentHash)"></i>
            </div>
            <div class="line-content">
              <span class="line-left">出&ensp;块&ensp;人：</span>
              <div class="line-right shenglue-2">{{ node.CoinBase }}</div>
              <i class="el-icon-document-copy"
                 style="margin-left:10px"
                 @click="copy($event, node.CoinBase)"></i>
            </div>

          </el-card>
        </el-col>

        <!-- 折线图 -->
        <el-col class="Bottom"
                :xl="14"
                :lg="12"
                :md="24"
                :xs="24">
          <el-card shadow="never"
                   class="middle-box">
            <line-chart :options="node.options"></line-chart>
          </el-card>
        </el-col>
      </el-row>
      <!-- 日志卡片 -->
      <el-row :gutter='24'>
        <el-card shadow="never"
                 class="small-left small-right">
          <el-col class="small-bottom line-name"
                  style="padding-left: 0px"
                  :xl='24'
                  :lg='24'
                  :md='24'
                  :xs='24'>
            <span>最新日志</span>
          </el-col>
          <!-- 日志框 -->
          <el-col :xl='24'
                  :lg='24'
                  :md='24'
                  :xs='24'
                  id="logBox"
                  class="log-box small-bottom">
            <!-- 日志内容 -->
            <pre v-html="node.newLog"></pre>
          </el-col>
          <el-col class="small-bottom line-name"
                  style="padding-left: 0px"
                  :xl='24'
                  :lg='24'
                  :md='24'
                  :xs='24'>
            <span>错误日志</span>
          </el-col>
          <!-- 日志框 -->
          <el-col :xl='24'
                  :lg='24'
                  :md='24'
                  :xs='24'
                  id="errorBox"
                  class="log-box small-bottom">
            <!-- 日志内容 -->
            <pre v-html="node.errorLog"></pre>
          </el-col>
        </el-card>
      </el-row>
    </el-card>

  </div>
</template>

<script>
import LineChart from './component/line-chart.vue'
import Clipboard from 'clipboard'
export default {
  components: { LineChart },
  data() {
    return {
      nodeData: [], // 节点信息列表
      addressList: [], // 节点地址列表
      timeOpt: [
        {
          value: 1000,
          label: '1s'
        },
        {
          value: 5000,
          label: '5s'
        },
        {
          value: 10000,
          label: '10s'
        }
      ],
      time: 5000,
      timer: '', // 请求节点定时器
      counts: 0, // 请求次数
      ip: '183.6.116.108:6009',
      optionObj: {
        options: {
          color: ['#5793f3', '#d14a61', '#675bba'],
          title: {
            text: '节点资源监控',
            textStyle: {
              fontSize: 16,
              color: '#222B45',
              fontWeight: 'bold'
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          legend: {
            data: [
              {
                name: 'CUP',
                // 强制设置图形为圆。
                icon: 'circle',
                textStyle: {
                  color: '#5793f3'
                }
              },
              {
                name: '内存',
                // 强制设置图形为圆。
                icon: 'circle',
                textStyle: {
                  color: '#d14a61'
                }
              }
            ]
          },
          grid: {
            top: 70,
            bottom: 30,
            left: 30
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              axisLine: {
                onZero: false
              },
              data: [],
              name: '时间',
              nameLocation: 'end'
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '占比(%)',
              nameLocation: 'end',
              nameTextStyle: {}
            }
          ],
          series: [
            {
              name: 'CUP',
              type: 'line',
              smooth: true,
              data: []
            },
            {
              name: '内存',
              type: 'line',
              smooth: true,
              data: []
            }
          ]
        }
      }
    }
  },
  computed: {},
  created() {
    if (this.$route.query.addressList) {
      console.log(this.$route.query.addressList)
      let addressList = JSON.parse(this.$route.query.addressList)
      this.addressList = addressList
      // 初始化节点折线图
      this.init(addressList)
      // 第一次请求节点信息
      this.getNode(addressList)
      // 定时获取节点数据
      this.getData(addressList, this.time)
    } else if (!JSON.parse(this.$route.query.addressList)) {
      this.$router.push('/node')
    }
  },
  mounted() {},
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    // getBack() {
    //   this.$router.push('/node')
    // },
    // 初始化折线图
    init(addressList) {
      for (let i = 0; i < addressList.length; i++) {
        let options = JSON.parse(JSON.stringify(this.optionObj))
        this.nodeData.push(options)
      }
    },
    // 定时请求
    getData(addressList, time) {
      let that = this
      clearInterval(this.timer)
      this.timer = setInterval(function () {
        that.getNode(addressList)
      }, time)
    },
    // 获取节点信息
    getNode(addressList) {
      // 计算请求次数
      this.counts = ++this.counts
      let temp = []
      for (let i = 0; i < addressList.length; i++) {
        temp.push(
          new Promise((resolve, reject) => {
            let that = this
            let nodeObj = {}
            let nodeObj1 = {}
            let nodeObj2 = {}
            let nodeObj3 = {}
            let obj1 = {}
            let obj2 = {}
            let obj3 = {}
            let obj4 = {}
            this.$set(obj1, 'Address', addressList[i].Address)
            // 获取节点信息
            let p1 = function () {
              // eslint-disable-next-line promise/param-names
              return new Promise((res1, rej1) => {
                // 关键代码
                that
                  .getNodeInfo(addressList[i].ip, addressList[i].port)
                  .then((infoObj) => {
                    res1(infoObj)
                  })
              })
            }
            // 获取最新日志
            let p2 = function () {
              // eslint-disable-next-line promise/param-names
              return new Promise((res1, rej1) => {
                // 关键代码
                that
                  .callLog(addressList[i].ip, addressList[i].port, 'info.log')
                  .then((info) => {
                    that.$set(obj2, 'newLog', info)
                    res1(obj2)
                    that.$nextTick(() => {
                      var div = document.getElementById('logBox')
                      div.scrollTop = div.scrollHeight
                    })
                  })
              })
            }
            // 获取错误日志
            let p3 = function () {
              // eslint-disable-next-line promise/param-names
              return new Promise((res1, rej1) => {
                // 关键代码
                that
                  .callLog(addressList[i].ip, addressList[i].port, 'error.log')
                  .then((error) => {
                    that.$set(obj3, 'errorLog', error)
                    res1(obj3)
                    that.$nextTick(() => {
                      var div = document.getElementById('errorBox')
                      div.scrollTop = div.scrollHeight
                    })
                  })
              })
            }
            // 获取节点资源信息
            let p4 = function () {
              // eslint-disable-next-line promise/param-names
              return new Promise((res1, rej1) => {
                // 关键代码
                that
                  .getNodeSys(addressList[i].ip, addressList[i].port)
                  .then((src) => {
                    // 取出options
                    if (src) {
                      // console.log('src:', src)
                      obj4 = JSON.parse(
                        JSON.stringify(that.nodeData[i].options)
                      )
                      // console.log('options:', obj4)
                      obj4.series[0].data.push(src.CPU)
                      obj4.series[1].data.push(src.memory)
                      // obj4.xAxis[0].data.push(that.getNowTime())
                      obj4.xAxis[0].data.push(src.systemTime)
                    }
                    // 判断如果刷新超过十次则删掉折线图第一个元素
                    if (that.counts > 10) {
                      // console.log(obj4.series)
                      obj4.series[0].data.shift()
                      obj4.series[1].data.shift()
                      obj4.xAxis[0].data.shift()
                    }
                    res1(obj4)
                  })
              })
            }
            // 按顺序请求
            p1()
              .then((res2) => {
                nodeObj = Object.assign(obj1, res2)
                return p2()
              })
              .then((res2) => {
                nodeObj1 = Object.assign(nodeObj, res2)
                return p3()
              })
              .then((res2) => {
                nodeObj2 = Object.assign(nodeObj1, res2)
                return p4()
              })
              .then((res2) => {
                let _Obj = JSON.parse(JSON.stringify(nodeObj2))
                let _Opt = JSON.parse(JSON.stringify(res2))
                let data = {}
                data.options = _Opt
                nodeObj3 = Object.assign(_Obj, data)
                resolve(nodeObj3)
              })
          })
        )
      }
      Promise.all(temp)
        .then((res) => {
          // console.log(res)
          this.$set(this, 'nodeData', res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取节点信息
    async getNodeInfo(ip, port) {
      let options = {
        json: JSON.stringify({
          mode: 'Chain',
          action: 'info'
        }),
        ip: ip,
        port: port
      }
      let { code, data } = await this.$api.home.nodeInfo(options)

      if (code === 200) {
        let _info = JSON.parse(data.message)
        let obj1 = {}
        this.$set(obj1, 'Height', _info.Chain.Height)
        // this.$set(obj1, 'Hash', '...' + this.subHash(_info.Chain.Hash, 34))
        this.$set(obj1, 'Hash', _info.Chain.Hash)
        // this.$set(
        //   obj1,
        //   'CoinBase',
        //   '...' + this.subHash(_info.Chain.CoinBase, 34)
        // )
        // this.$set(
        //   obj1,
        //   'ParentHash',
        //   '...' + this.subHash(_info.Chain.ParentHash, 34)
        // )
        this.$set(obj1, 'CoinBase', _info.Chain.CoinBase)
        this.$set(obj1, 'ParentHash', _info.Chain.ParentHash)
        this.$set(obj1, 'Difficulty', _info.Chain.Difficulty)
        this.$set(obj1, 'Nonce', _info.Chain.Nonce)
        this.$set(
          obj1,
          'Timestamp',
          this.timestampToTime(_info.Chain.Timestamp)
        )
        this.$set(obj1, 'Version', _info.Version)
        this.$set(obj1, 'PeerType', _info.Setting.PeerType)
        this.$set(obj1, 'ShardName', _info.Setting.ShardName)
        this.$set(obj1, 'Port', _info.Setting.Port)
        this.$set(obj1, 'Pending', _info.Pool.Pending)
        this.$set(obj1, 'Completed', _info.Pool.Completed)
        return obj1
      } else {
        clearInterval(this.timer)
      }
    },
    // 获取系统资源
    async getNodeSys(ip, port) {
      let options = {
        json: JSON.stringify({
          mode: 'System',
          action: 'info'
        }),
        ip: ip,
        port: port
      }
      let { code, data } = await this.$api.home.nodeInfo(options)
      if (code === 200) {
        let _info = JSON.parse(data.message)
        // 定义一个资源对象
        let srcObj = {}
        // 内存计算
        srcObj.memory =
          Math.round(
            (_info.GlobalMemory.Available / _info.GlobalMemory.Total) * 10000
          ) / 100
        // CPU计算
        let CPUStr = _info.CentralProcessor.ProcessorCpuLoadBetweenTicks
        let array = CPUStr.split('%;')
        let sum = 0
        for (let i = 0; i < array.length - 1; i++) {
          sum += parseInt(array[i] * 100)
        }
        srcObj.CPU = Math.ceil(sum / array.length - 1) / 100
        srcObj.systemTime = this.getNowTime(
          _info.NetworkInterface[0].TimeStamp
        )

        return srcObj
      } else {
        clearInterval(this.timer)
      }
    },
    // 获取节点最新日志
    async callLog(ip, port, type) {
      let options = {
        json: JSON.stringify({
          mode: 'System',
          action: 'loggerLast',
          logger: type
        }),
        ip: ip,
        port: port
      }
      let { code, data } = await this.$api.home.nodeInfo(options)
      if (code === 200) {
        if (type === 'info.log') {
          // 处理正常日志
          let array = data.message.split('[INFO ]')
          array.splice(0, 1)
          let str = ''
          for (let i = array.length - 1; i >= 0; i--) {
            str += '[INFO]' + array[i] + ''
          }
          return str
        } else {
          // 处理错误日志
          let list = data.message.split('\n')
          let error = ''
          for (let j = list.length - 2; j >= 0; j--) {
            error += list[j] + '\n' + ''
          }
          return error
        }
      } else {
        clearInterval(this.timer)
      }
    },
    // 跳转首页
    toHome() {
      this.$router.push('/home')
    },
    // 刷新条件
    handleChange() {
      console.log('改变刷新时间:', this.time)
      clearInterval(this.timer)
      // 立刻请求节点信息
      this.getNode(this.$route.query.addressList)
      // 定时获取节点数据
      this.getData(this.$route.query.addressList, this.time)
    },
    // 截取字符串函数
    subHash(hash, i) {
      let disName = hash
      let disLength = disName.length
      let shortName = disName.substring(disLength - i, disLength)
      return shortName
    },
    // 时间戳转化日期格式
    timestampToTime(timestamp) {
      let date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + '-'
      let M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      let D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      let h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
      let s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    },
    // 获取最新时间并转化时分秒
    getNowTime(timestamp) {
      let date = new Date(timestamp)
      let h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
      let s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      let currentTime = h + m + s
      return currentTime
    },
    // 一键复制
    copy(e, text) {
      if (this.copyLoading) {
        return
      }
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
      setTimeout(() => {
        this.copyLoading = false
      }, 1000)
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/.el-card__header {
  padding: 20px 20px 12px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}
.head-title {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  margin-right: 16px;
}
.list-select {
  width: 120px;
}
.card-box {
  border: 0;
  .node-title {
    margin-left: 12px;
    font-size: 16px;
    color: #555555;
    font-weight: bold;
  }
  .line-icon {
    width: 48px;
    height: 48px;
    float: right;
    margin-bottom: -10px;
    margin-top: -10px;
  }
  .left-box {
    display: flex;
    flex-direction: column;
    height: 405px;
    .line-content {
      margin-left: 4px;
      display: flex;
      flex-direction: row;
      min-width: 480px;
      word-wrap: break-word;
      .line-left {
        width: 90px;
      }
      .line-right {
        width: 400px;
      }
      .line-right-1 {
        width: 180px;
      }
      .line-right-2 {
        width: 140px;
      }
    }
  }
  .middle-box {
    height: 405px;
  }
  .right-box {
    height: 400px;
    display: flex;
    flex-direction: column;
    .log-box {
      color: white;
      height: 180px;
      background: rgb(0, 0, 0);
      padding: 10px;
      overflow-y: scroll;
      overflow-x: hidden;
      word-wrap: break-word;
      white-space: nowrap;
    }
    .log-box::-webkit-scrollbar {
      display: none;
    }
  }
}
.line-name {
  font-size: 16px;
  color: #222b45;
  font-weight: 600;
}
// 日志框
.log-box {
  width: calc(100% - 0px);
  background: rgba(0, 0, 20, 0.8);
  overflow-x: hidden;
  overflow-y: scroll;
}

/deep/ pre {
  color: white;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 28px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  .infoCls {
    color: white;
    overflow-wrap: break-word;
  }
  .finishCls {
    color: rgb(57, 120, 255);
    overflow-wrap: break-word;
  }
  .successCls {
    color: $--color-success;
    overflow-wrap: break-word;
  }
  .errorCls {
    color: $--color-danger;
    overflow-wrap: break-word;
  }
}
/* -- 滚动条 start -- */
::v-deep {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* !*定义滚动条轨道 内阴影+圆角*! */
  ::-webkit-scrollbar-track {
    /*滚动条里面小方块*/
    background: rgba(255, 255, 255, 0.1);
  }

  /*!*定义滑块 内阴影+圆角*!*/
  ::-webkit-scrollbar-thumb {
    /*滚动条里面轨道*/
    // border-radius: 10px;
    background: rgba(255, 255, 255, 0.4);
  }
}
/* -- 滚动条 end -- */

/*边距*/
.small-left {
  margin-left: 12px;
}
.small-right {
  margin-right: 12px;
}
.small-bottom {
  margin-bottom: 12px;
}
.Bottom {
  margin-bottom: 24px;
}
</style>
