<template>
  <div class="echart-comp">
    <!--折线-->
    <div :id="id" style="width: 100%;height:360px" :options="options"></div>
  </div>
</template>

<script>
const echarts = require('echarts/lib/echarts')
require('echarts/theme/macarons') // echarts theme
// 引入折线图
require('echarts/lib/chart/line')
export default {
  name: 'Echart',
  props: {
    width: {
      default: '100%'
    },
    height: {
      default: '100%'
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chart: null,
      id: 'chart' + Math.ceil(Math.random() * 10) * Math.ceil(Math.random() * 1000)
    }
  },
  computed: {},
  watch: {
    options: {
      handler () {
        this.update()
      },
      deep: true,
      immediate: true
    }
  },
  created () {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let _this = this
      // 基于准备好的dom，初始化echarts实例
      this.chart = echarts.init(document.getElementById(this.id), 'macarons')

      // 绘制图表
      this.chart.setOption(this.options)
      // 响应屏幕大小重绘
      window.addEventListener('resize', function () {
        _this.chart.resize()
      })
    },
    update () {
      if (this.chart) {
        // 更新配置
        this.chart.setOption(this.options)
      }
    }
  }
}
</script>

<style lang="scss">
.echart-comp {
  height: 100%;
  position: relative;
  & > .nodata {
    position: absolute;
    top: 50%;
    left: 40%;
    z-index: 2;
    color: #999;
  }
}
</style>
